import React from 'react';

import "./Footer.css"; // Update the CSS file name if needed

const Footer = () => {
  return (
    <div className="footer">
      <span>Admin</span>
      <span>© Yathra Admin Dashboard</span>
    </div>
  );
}

export default Footer;
